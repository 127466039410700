<template>
  <b-container fluid>
    <bo-page-title />

    <b-card>
      <b-row>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Status" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.view" :options="taskOption"></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row v-if="filter.view == 'today-task'">
        <b-col md=12>
          <b-card class="border">
            <b-card-header>
              <h4 class="card-title">Today Tasks <sup>({{ totalTaskToday }})</sup></h4>
            </b-card-header>
            <b-card-body>
              <div class="row" v-if="taskToday.length">
                <b-col md=12 v-for="(task, index) in taskToday" :key="index">
                  <div>
                    <h4 class="fw-bold">{{ task.category }}</h4>
                    <ul>
                      <li class="list-item" v-for="(item, index2) in task.task" :key="`${index}${index2}`">
                        <div class="item d-flex">
                          <div v-if="item.tta_status != 'failed' && moduleRole('finish')" @click="checkTask(item)" :class="{checkbox: true, active: item.tta_is_finish == 'Y'}">
                            <i class="fas fa-check"></i>
                          </div>  
                            <p class="item-title">{{item.mti_title}}</p>
                            <p class="item-date">
                             <span>{{ item.tta_pic_name }}</span>
                             <span class="mx-2">&#x2022;</span>
                             <span>{{timeOfUse(item.tta_start_date, item.tta_end_date, false)}}</span>
                            </p>
                          <div>
                            <div class="item-action fade ml-4 d-flex align-items-center" v-if="moduleRole('fail')">
                              <b-button v-if="item.tta_status == 'not-yet'" class="btn-icon" @click="failTask(item)" variant="outline-danger" v-b-tooltip.hover="'Fail'"><i class="fas fa-times"></i></b-button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </b-col>
              </div>
              <h3 class="text-center" v-else>There is no 'Today Tasks' now</h3>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else-if="filter.view == 'upcoming-task'">
        <b-col md=12>
          <b-card class="border">
            <b-card-header>
              <h4 class="card-title">Upcoming Task <sup>({{ upComingTask.length }})</sup></h4>
            </b-card-header>
            <b-card-body>
              <div class="row" v-if="upComingTask.length">
                <b-col md=12 v-for="(task, index) in upComingTask" :key="index">
                  <div class="date-wrapper">
                    <p class="title-date fw-bold">{{ task.date | moment('ddd, DD MMM YY')}}</p>
                    <div class="pl-3" v-for="(category, index2) in task.task" :key="`${index}${index2}`">
                      <p class="title-category">{{ category.category }}</p>
                      <ul>
                        <li class="list-item" v-for="(item, index3) in category.items" :key="`${index}${index2}${index3}`">
                          <div class="item d-flex">
                            <div v-if="item.tta_status != 'failed' && moduleRole('finish')" @click="checkTask(item)" :class="{checkbox: true, active: item.tta_is_finish == 'Y'}">
                              <i class="fas fa-check"></i>
                            </div>  
                              <p class="item-title">{{item.mti_title}}</p>
                              <p class="item-date">
                              <span>{{ item.tta_pic_name }}</span>
                              <span class="mx-2">&#x2022;</span>
                              <span>{{timeOfUse(item.tta_start_date, item.tta_end_date, false)}}</span>
                              </p>
                            <div>
                              <div class="item-action fade ml-4 d-flex align-items-center" v-if="moduleRole('fail')">
                                <b-button v-if="item.tta_status == 'not-yet'" class="btn-icon" @click="failTask(item)" variant="outline-danger" v-b-tooltip.hover="'Fail'"><i class="fas fa-times"></i></b-button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>
              </div>
              <h3 class="text-center" v-else>There is no 'Today Tasks' now</h3>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else-if="filter.view == 'pending-task'">
        <b-col md=12>
          <b-card class="border">
            <b-card-header>
              <h4 class="card-title">Pending Task <sup>({{ totalTaskPending }})</sup></h4>
            </b-card-header>
            <b-card-body>
              <div class="row" v-if="pendingTask.length">
                <b-col md=12 v-for="(task, index) in pendingTask" :key="index">
                  <div class="date-wrapper">
                    <p class="title-date fw-bold">{{ task.date | moment('ddd, DD MMM YY')}}</p>
                    <div class="pl-3" v-for="(category, index2) in task.task" :key="`${index}${index2}`">
                      <p class="title-category">{{ category.category }}</p>
                      <ul>
                        <li class="list-item" v-for="(item, index3) in category.items" :key="`${index}${index2}${index3}`">
                          <div class="item d-flex">
                            <div v-if="item.tta_status != 'failed' && moduleRole('finish')" @click="checkTask(item)" :class="{checkbox: true, active: item.tta_is_finish == 'Y'}">
                              <i class="fas fa-check"></i>
                            </div>  
                            <p class="item-title">{{item.mti_title}}</p>
                            <p class="item-date">
                              <span>{{ item.tta_pic_name }}</span>
                              <span class="mx-2">&#x2022;</span>
                              <span>{{timeOfUse(item.tta_start_date, item.tta_end_date, false)}}</span>
                            </p>
                            <div>
                              <div class="item-action fade ml-4 d-flex align-items-center" v-if="moduleRole('fail')">
                                <b-button v-if="item.tta_status == 'not-yet'" class="btn-icon" @click="failTask(item)" variant="outline-danger" v-b-tooltip.hover="'Fail'"><i class="fas fa-times"></i></b-button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </b-col>
              </div>
              <h3 class="text-center" v-else>There is no 'Pending Tasks' now</h3>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else-if="filter.view == 'waiting-evaluation'">
        <b-col md=12>
          <b-card class="border">
            <b-card-header>
              <h4 class="card-title">Waiting for Evaluation <sup>({{needEvaluation.total}})</sup></h4>
            </b-card-header>
            <b-card-body>
              <div class="row" v-if="needEvaluation.total">
                <b-col md=6 lg=4 v-for="(task, index) in (needEvaluation.data || [])" :key="index">
                  <div class="card-list d-flex mb-2">
                    <div class="item c-pointer">
                      <span class="text-category">{{task.tta_task_category_name}}</span>
                      <b-badge class="ml-2" :variant="status(task.tta_status).variant">{{status(task.tta_status).label}}</b-badge>
                      <p class="text-task">{{task.mti_title}}</p>
                      <span class="text-pic">{{task.tta_pic_name}}</span>
                      <p class="text-date">{{task.tta_start_date | moment('LL')}} s.d. {{ task.tta_end_date | moment('LL') }}</p>
                    </div>
                    <div class="actions ml-4 d-flex align-items-center">
                      <b-button v-if="task.tta_status == 'not-yet'" class="btn-icon" @click="failTask(task)" variant="outline-danger" v-b-tooltip.hover="'Fail'"><i class="fas fa-times"></i></b-button>
                    </div>
                  </div>
                </b-col>
              </div>
              <h3 class="text-center" v-else>There is no 'Waiting for Evaluation' Now</h3>
            </b-card-body>
            <b-card-footer v-if="needEvaluation.total" class="d-flex justify-content-end">
              <b-pagination
                class="mb-0"
                v-model="pageNE"
                :per-page="needEvaluation.per_page"
                :total-rows="needEvaluation.total"
              />
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-modal 
      id="finishTask"
      title="Finish Task"
      hide-footer
      :no-enforce-focus="true"
      no-close-on-backdrop
      centered
      >
        <ValidationObserver ref="VFormFinishTask" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(finishTask)">
            <b-form-group label-for="finishBy">
              <template #label>Task</template>
              <p>{{ dataModal.mti_title }}</p>
            </b-form-group>
            <b-form-group label-for="finishBy">
              <template #label>Finish By <strong class="text-danger">*</strong></template>
              <v-select @input="setPicName($event)" v-model="dataModal.tta_finish_by_id" placeholer="Finish By" :options="userGa" :reduce="data => data.value" />
              <VValidate name="Finish By" v-model="dataModal.tta_finish_by_id" rules="required" />
            </b-form-group>
            <b-form-group label-for="note">
              <template #label>Notes</template>
              <b-form-textarea id="note"
                v-model="dataModal.tta_note"
                :rows="4"
                placeholder="cth: Perlu di monitor di minggu depan." />
            </b-form-group>
            <b-form-group>
              <Uploader v-model="dataModal.tta_image" type="dropbox"/>
            </b-form-group>
            <b-row class="justify-content-end">
              <b-col md='auto'>
                <b-button type="button" variant="transparent" @click="doCloseModal('finishTask')">Cancel</b-button>
                <b-button type="submit" variant="success btn-rounded">Submit</b-button>
              </b-col>
            </b-row> 
          </b-form>
        </ValidationObserver>
    </b-modal>
    <b-modal 
      id="failedTask"
      title="Failed Task"
      hide-footer
      no-close-on-backdrop
      :no-enforce-focus="true"
      centered
      >
        <ValidationObserver ref="VFormFailedTask" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(doFailedTask)">
            <b-form-group label-for="note">
              <template #label>Failed Reason <strong class="text-danger">*</strong></template>
              <b-form-textarea id="note"
                v-model="dataModal.tta_failed_reason"
                :rows="4"
              />
              <VValidate name="Failed Reason" v-model="dataModal.tta_failed_reason" rules="required" />
            </b-form-group>
            <b-row class="justify-content-end">
              <b-col md='auto'>
                <b-button type="button" variant="transparent" @click="doCloseModal('failedTask')">Cancel</b-button>
                <b-button type="submit" variant="success btn-rounded">Submit</b-button>
              </b-col>
            </b-row> 
          </b-form>
        </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<style lang="scss" scoped>
.item-action {
  margin-right: 15rem;
}
.date-wrapper {
  padding: 10px 18px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 10px;
}
.fw-bold{
  font-weight: 500;
}
ul {
  padding: 0 0 0 16px;
}
.item {
  padding: 16px 0 0 0;
  border-top: 1px solid #EEE;
  border-bottom: 1px solid #eee;
}
.item-title {
  font-size: 16px;
}
.item-date{
  color: #555;
  margin-left: auto;
}
.list-item{
  list-style: none;
}
.fade {
  opacity: 0;
  visibility: hidden;
}

.title-date {
  font-size: 20px;
}
.title-category {
  font-size: 18px;
  color: #666;
}

.item:hover{
  .fade {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s;
  }
}


.checkbox {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  cursor: pointer;
}
.checkbox.active {
  background: #00A150;
}
.c-pointer {
  cursor: pointer;
}
.text-category {
  font-size: 12px;
}
.text-task {
  font-size: 16px;
  margin-bottom: 8px;
}
.text-pic {
  font-size: 12px;
  font-weight: 500;
}
.card-list {
    padding: 20px 0 0 20px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.card-list:hover {
    background: #eeededcc;
}
</style>
<script>
	import GlobalVue from '@/libs/Global.vue'
  import Gen from '@/libs/Gen.js'
  
  export default {
		extends: GlobalVue,
    data() {
      return {
        idKey: 'tbt_id',
        taskToday: [],
        upComingTask: [],
        dataModal: {},
        userGa: [],
        needEvaluation: [],
        totalTaskToday: 0,
        totalTaskPending: 0,
        pendingTask: [],
        taskOption: [
          {
            label: 'Pending Task',
            value: 'pending-task'
          },
          {
            label: 'Today Task',
            value: 'today-task'
          },
          {
            label: 'Upcoming Task',
            value: 'upcoming-task'
          },
          {
            label: 'Waiting for Evaluation',
            value: 'waiting-evaluation'
          }
        ]
      }
    },
    mounted(){
      this.setFilter()
      this.apiGet()
    },
    methods: {
      setFilter(){
        this.$set(this.filter, 'view', this.$route.query.view || 'today-task')
      },
      setPicName(event){
        const picName = this.userGa.find(v => v.value == event)
        if(picName){
          this.$set(this.dataModal, 'tta_finish_by', picName.label)
        }
      },
      doFailedTask(){
        if(this.moduleRole('fail'))
        this.$refs['VFormFailedTask'].validate().then(success => {
          if(!success) return
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          Gen.apiRest('/do/' + this.modulePage, { data: Object.assign({type: 'failed-task' },  {...this.dataModal}) }, "POST").then(res => {
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(()=> {
              this.$bvModal.hide('failedTask')
              this.apiGet()
            })
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        })
      },
      doCloseModal(modal){
        this.dataModal = {}
        this.$bvModal.hide(modal)
      },
      failTask(item){

        this.dataModal = Object.assign({}, {...item})
        this.$bvModal.show('failedTask')
      },
      finishTask(){
        this.$refs['VFormFinishTask'].validate().then(success => {
          if(!success) return
          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })
          Gen.apiRest('/do/' + this.modulePage, { data: Object.assign({type: 'finish-task' },  {...this.dataModal}) }, "POST").then(res => {
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(()=> {
              this.$bvModal.hide('finishTask')
              this.apiGet()
            })
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        })
      },
      checkTask(task){
        if(this.moduleRole('finish')){
          const e = task.tta_is_finish == 'Y' ? 'N' : 'Y'
        
          if(e == 'Y' && ['failed'].indexOf(task.tta_status) == -1){
            this.dataModal = Object.assign({}, task)
            this.dataModal.tta_finish_by_id = task.tta_pic
            this.dataModal.tta_finish_by = task.tta_pic_name
            this.dataModal.tta_is_finish = e
            this.$bvModal.show('finishTask')
          }
        }
      },
      status(status){
        const listStatus = {
          'not-yet': {
            status: 'not-yet',
            label: 'Not Yet',
            variant: 'warning'
          },
          'evaluation': {
            status: 'evaluation',
            label: 'Evaluation',
            variant: 'info'
          },
          'finish': {
            status: 'finish',
            label: 'Finsih',
            danger: 'success'
          },
          'failed': {
            status: 'failed',
            label: 'Failed',
            variant: 'danger'
          }
        }

        return listStatus [status]
      },
    },
    watch:{
      $route(){
        this.setFilter()
        this.apiGet()
      },
    },
    computed: {
      pageNY: {
        // getter
        get:function() {
          return this.filter.pageny||this.$route.query.pageny||1
        },
        // setter
        set:function(newValue) {
          this.$set(this.filter, "pageny", newValue)
          this.$router.push({
            name:this.modulePage,
            query: Object.assign({}, {...this.filter})
          })
        }
      },
      pageNE: {
        // getter
        get:function() {
          return this.filter.pagene||this.$route.query.pagene||1
        },
        // setter
        set:function(newValue) {
          this.$set(this.filter, "pagene", newValue)
          this.$router.push({
            name:this.modulePage,
            query: Object.assign({}, {...this.filter})
          })
        }
      },
    }
  }
</script>